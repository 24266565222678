import dayjs from 'dayjs';
import { Link } from 'gatsby';
import React from 'react';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

const BlogPostExcerpt = ({ post }) => (
    <div className="blog-list-item" >
        <article
            className={`tile is-child box ${post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
        >
            <Link to={post.fields.slug} >
                <header>
                    {post.frontmatter.featuredimage ? (
                        <div className="featured-thumbnail">
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                }}
                            />
                        </div>
                    ) : null}
                    <p className="post-meta">
                        <span className="title has-text-primary">
                            {post.frontmatter.title}
                        </span>
                        <span className="tag is-block">
                            {post.frontmatter.tags[0]}
                        </span>
                        <span className="subtitle is-block">
                            {dayjs(post.frontmatter.date).format('DD/MM/YYYY')}
                        </span>
                    </p>
                </header>
                <p className="post-excerpt">
                    {post.excerpt}
                </p>
            </Link>
        </article>
    </div>
);

export default BlogPostExcerpt;