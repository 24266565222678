import { Link } from 'gatsby';
import React from 'react';

import './BlogBanner.scss';

const BlogBanner = () => (
    <section className="blog-banner">
        <h2 className="text has-text-centered title">CONTRIBUA PARA O AMIGOS DA POLI</h2>
        <p className="text has-text-centered disclaimer">Faça sua doação e ajude o Amigos da Poli a chegar mais longe!</p>
        <Link className="button is-link--red" to="https://doe.amigosdapoli.com.br">
            quero doar!
        </Link>
    </section>

);

export default BlogBanner;
