import { debounce } from 'lodash';
import React from 'react';

const BlogFilter = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            tag: ''
        };
        this.dropdownLeave = this.dropdownLeave.bind(this);
        this.dropdownSelect = this.dropdownSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown(event) {
        this.setState({
            isActive: !this.state.isActive
        });
    }

    dropdownLeave() {
        this.setState({ isActive: false });
    }

    dropdownSelect(tag) {
        tag = tag === this.state.tag ? '' : tag;
        this.props.onTagSelect(tag);
        this.setState({
            isActive: !this.state.isActive,
            tag: tag
        });
    }

    handleInputChange(event) {
        event.persist();
        if (!this.debouncedFn) {
            this.debouncedFn = debounce(() => this.props.onQueryType(event.target.value), 300);
        }
        this.debouncedFn();
    }

    render() {
        const { tags } = this.props;
        return (
            <section className="blog-filter">
                <div className={"dropdown " + (this.state.isActive ? 'is-active' : '')} onMouseLeave={this.dropdownLeave}>
                    <div className="dropdown-trigger">
                        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={this.toggleDropdown}>
                            <span className="dropdown-label">{this.state.tag || 'Filtros'}</span>
                            <span className="icon is-small">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.422" height="7.565" viewBox="0 0 14.422 7.565">
                                    <defs>
                                        <style>{".a { fill: none; stroke: #30bcda; }"}</style>
                                    </defs>
                                    <g transform="translate(0.354 0.354)">
                                        <line class="a" x2="6.857" y2="6.857" transform="translate(0)" />
                                        <line class="a" x1="6.857" y2="6.857" transform="translate(6.857)" />
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            {tags.map((tag, i) => (
                                <a key={`${tag}_${i}`} className="dropdown-item" onClick={() => this.dropdownSelect(tag)}>{tag}</a>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="search-field">
                    <p className="control has-icons-left">
                        <input className="input" type="text" placeholder="Buscar" onChange={this.handleInputChange} />
                        <span className="icon is-small is-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <defs>
                                    <style>{".a{fill:#30bcda;}"}</style>
                                </defs>
                                <path class="a" d="M48.723,47.383a.947.947,0,1,1-1.34,1.34L42.748,44.09a.587.587,0,0,0-.718-.067,4.223,4.223,0,0,1-.4.255,7.1,7.1,0,1,1,2.64-2.631,3.725,3.725,0,0,1-.241.377.586.586,0,0,0,.063.723ZM38.105,43.316a5.211,5.211,0,1,0-5.211-5.211A5.211,5.211,0,0,0,38.105,43.316Z" transform="translate(-31 -31)" />
                            </svg>
                        </span>
                    </p>
                </div>
            </section>
        );
    }
};

export default BlogFilter;