import { graphql, Link, StaticQuery } from 'gatsby';
import { flattenDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import BlogBanner from './BlogBanner';
import BlogFeatured from './BlogFeatured';
import BlogFilter from './BlogFilter';
import BlogPostExcerpt from './BlogPostExcerpt';

import './BlogRoll.scss';

const PAGES_WINDOW = 3;
const POSTS_PER_PAGE = 9;
class BlogRoll extends React.Component {

  constructor(props) {
    super(props);

    const posts = props.data.allMarkdownRemark.edges;
    const tags = flattenDeep(posts.map(({ node: post }) => post.frontmatter.tags))

    const pages = [];
    for (let i = 0; i < props.count - 1; i += POSTS_PER_PAGE) {
      pages.push(i / POSTS_PER_PAGE + 1);
    }

    this.state = {
      featuredPost: posts[0],
      posts: posts.slice(1),
      filteredPosts: posts.slice(1),
      tag: '',
      query: '',
      page: 1,
      lastPage: props.count / POSTS_PER_PAGE,
      tags: Array.from(new Set(tags))
    };

    this.goToPage = this.goToPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.tagFilter = this.tagFilter.bind(this);
  }

  calcStateParameters(filteredPosts, page = this.state.page) {
    const count = filteredPosts.length;
    const first = (page - 1) * POSTS_PER_PAGE;
    let last = Math.floor((count - (POSTS_PER_PAGE * page)) % POSTS_PER_PAGE);
    last = page === this.state.lastPage ? last : POSTS_PER_PAGE + 1;
    return {
      count,
      first,
      page,
      last
    };
  }

  filterPost(tag, query) {
    return ({ node: post }) => {
      return (!tag || post.frontmatter.tags.includes(tag)) &&
        (!query
          || post.frontmatter.title.toLowerCase().includes(query)
          || post.excerpt.toLowerCase().includes(query)
        )
    }
  }

  goToPage(page) {
    page = page < 1 ? 1 : page;
    page = page > this.state.lastPage ? this.state.lastPage : page;

    this.setState({ page });
  }

  searchFilter(query) {
    query = query.toLowerCase();
    const filteredPosts = this.state.posts.filter(this.filterPost(this.state.tag, query));
    const {
      first,
      last,
      page,
      count
    } = this.calcStateParameters(filteredPosts);
    this.setState({
      filteredPosts: filteredPosts.slice(first, last),
      page: count - page * POSTS_PER_PAGE < -(POSTS_PER_PAGE - 1) ? count / POSTS_PER_PAGE : page,
      query
    });
  }

  tagFilter(tag) {
    const filteredPosts = this.state.posts.filter(this.filterPost(tag, this.state.query));
    const {
      first,
      last,
      page,
      count
    } = this.calcStateParameters(filteredPosts);
    this.setState({
      filteredPosts: filteredPosts.slice(first, last),
      page: count - page * POSTS_PER_PAGE < -8 ? count / POSTS_PER_PAGE : page,
      tag
    });
  }

  Pages(props) {
    const pages = [];
    let pageFirst;
    let pageLast;
    if (props.lastPage > PAGES_WINDOW) {
      pageFirst = props.page === 1
        ? <span className="page inactive">&laquo;</span>
        : <Link to="#" className="page" onClick={() => props.goToPage(1)}>&laquo;</Link>
      pageLast = props.page === props.lastPage
        ? <span className="page inactive">&raquo;</span>
        : <Link to="#" className="page" onClick={() => props.goToPage(props.lastPage)}>&raquo;</Link>
    }
    if (props.page === 1) {
      pages.push(<span className="page active">1</span>);
      pages.push(<Link to="#" className="page" onClick={() => props.goToPage(2)}>2</Link>);
      
      if (props.lastPage >= PAGES_WINDOW) {
        pages.push(<Link to="#" className="page" onClick={() => props.goToPage(3)}>3</Link>);
      }
    } else if (props.page === props.lastPage) {
      if (props.lastPage >= PAGES_WINDOW) {
        pages.push(<Link to="#" className="page" onClick={() => props.goToPage(props.lastPage - 2)}>{props.lastPage - 2}</Link>);
      }
      pages.push(<Link to="#" className="page" onClick={() => props.goToPage(props.page - 1)}>{props.page - 1}</Link>);
      pages.push(<span className="page active">{props.lastPage}</span>);
    } else {
      pages.push(<Link to="#" className="page" onClick={() => props.goToPage(props.page - 1)}>{props.page - 1}</Link>);
      pages.push(<span className="page active">{props.page}</span>);
      pages.push(<Link to="#" className="page" onClick={() => props.goToPage(props.page + 1)}>{props.page + 1}</Link>);
    }

    return (
      <div className="pages">
        {pageFirst}
        {pages}
        {pageLast}
      </div>
    );
  }

  render() {
    const posts = this.state.filteredPosts;
    const page = this.state.page;
    const firstIndex = (page - 1) * POSTS_PER_PAGE;
    const middleIndex = posts.length < 8 ? 3 : firstIndex + 6;
    const lastCount = posts.length < 8 ? 3 : this.props.count / POSTS_PER_PAGE > page ? PAGES_WINDOW : (POSTS_PER_PAGE - this.props.count % POSTS_PER_PAGE);
    
    return (
      <div id="blog-page">
        <BlogFeatured post={this.state.featuredPost.node} />
        <BlogFilter tags={this.state.tags} onQueryType={this.searchFilter} onTagSelect={this.tagFilter} />
        <div id="blog" className="blog">
          {this.props.count > 1 && <div className="blog-list">
            {posts.slice(firstIndex, middleIndex).map(({ node: post }) => (<BlogPostExcerpt post={post} key={post.id} />))}
          </div>}
          <BlogBanner />
          {((lastCount - middleIndex) >= 0) && <div className="blog-list">
            {posts.slice(middleIndex, middleIndex + lastCount).map(({ node: post }) => (<BlogPostExcerpt post={post} key={post.id} />))}
          </div>}
          {this.props.count > 10 && <div className="blog-paginator">
            <this.Pages page={page} goToPage={this.goToPage} lastPage={this.state.lastPage} />
          </div>}
        </div>
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data) => <BlogRoll data={data} count={data.allMarkdownRemark.edges.length} />}
  />
)
