import dayjs from 'dayjs';
import { Link } from 'gatsby';
import React from 'react';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

const BlogFeatured = ({ post }) => (
    <section className="blog-featured">
        <Link to={post.fields.slug} >
            {post.frontmatter.featuredimage ? (
                <div className="featured-thumbnail">
                    <PreviewCompatibleImage
                        imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                    />
                </div>
            ) : null}
            <article>
                <header>
                    <p className="post-meta">
                        <span className="tag is-block">
                            {post.frontmatter.tags[0]}
                        </span>
                        <span className="date is-block">
                            {dayjs(post.frontmatter.date).format('DD/MM/YYYY')}
                        </span>
                    </p>
                    <p className="post-title has-text-primary">
                        {post.frontmatter.title}
                    </p>
                </header>
                <main className="post-excerpt">
                    {post.excerpt}
                </main>
            </article>
        </Link>
    </section>

);

export default BlogFeatured;